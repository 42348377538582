import { APIChannel } from 'app/api/routes/channels';
import { APIIdentity } from 'app/api/routes/identities';
import { APIOrganizationProfile } from 'app/api/routes/organizationProfiles';
import { APIProfile } from 'app/api/routes/profiles';
import { APIUser } from 'app/api/routes/users';
import appConfig from 'app/config/appConfig';
import { CHANNEL_THEME, Theme } from 'app/constants/theme';
import useApi from 'app/hooks/useApi';
import useApiRequest from 'app/hooks/useApiRequest';
import useSetUserProperty from 'app/hooks/useSetUserProperty';
import pushToDataLayer from 'app/utils/push-to-data-layer.web';
import merge from 'lodash/merge';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import useAsync from 'react-use/lib/useAsync';
import { KeyedMutator } from 'swr';
import { useToken } from '../token';

type ProfileContext = {
  me?: APIUser;
  profile?: APIProfile;
  orgProfile?: APIOrganizationProfile;
  identity?: APIIdentity;
  identities?: APIIdentity[];
  channel?: APIChannel;
  theme: Theme;
  mutateMe: KeyedMutator<APIUser>;
  mutateProfile: KeyedMutator<APIProfile>;
  mutateOrgProfile: KeyedMutator<APIOrganizationProfile>;
  mutateIdentities: KeyedMutator<APIIdentity[]>;
  isLoading?: boolean;
  isSettingIdentity?: boolean;
};

export const ProfileContext = React.createContext<ProfileContext>({
  mutateMe: () => Promise.resolve(undefined),
  mutateProfile: () => Promise.resolve(undefined),
  mutateOrgProfile: () => Promise.resolve(undefined),
  mutateIdentities: () => Promise.resolve(undefined),
  theme: CHANNEL_THEME['']!,
});

export type ProfileProviderProps = React.PropsWithChildren<ProfileContext>;

enum LOAD_STATE {
  INIT = 1,
  LOADING = 2,
  LOADED = 3,
}

export const ProfileProvider: React.FC<
  React.PropsWithChildren<{
    fallbackChannel?: APIChannel;
    fallbackMe?: APIUser;
    fallbackProfile?: APIProfile;
    fallbackIdentity?: APIIdentity;
  }>
> = ({ children, fallbackChannel, fallbackMe, fallbackProfile, fallbackIdentity }) => {
  const api = useApi();
  const { initializing, token, logout, identity: activeIdentity } = useToken();
  const { setUserProperty } = useSetUserProperty();

  const [loadState, setLoadState] = useState(LOAD_STATE.INIT);

  const {
    data: me,
    isLoading: meIsLoading,
    mutate: mutateMe,
    error: meError,
  } = useApiRequest(api.users.fetchMe(), {
    fallbackData: fallbackMe,
    keepPreviousData: true,
  });

  const {
    data: identities,
    isLoading: identitiesIsLoading,
    mutate: mutateIdentities,
  } = useApiRequest(api.token ? api.identities.fetchAvailable() : null, {
    fallbackData: fallbackIdentity ? [fallbackIdentity] : [],
    keepPreviousData: true,
  });

  const _identity = identities?.find(
    (identity) => identity.id === activeIdentity || identity.id === me?.activeIdentity
  );
  const identity = _identity ?? fallbackIdentity;

  const {
    data: profile,
    isLoading: profileIsLoading,
    mutate: mutateProfile,
  } = useApiRequest(identity?.type === 'member' ? api.profiles.fetchMe() : null, {
    fallbackData: fallbackProfile,
    keepPreviousData: true,
  });

  const {
    data: orgProfile,
    isLoading: orgProfileIsLoading,
    mutate: mutateOrgProfile,
  } = useApiRequest(
    identity?.type === 'organization' && identity?.slug
      ? api.organizationProfiles.detail(identity.slug)
      : null
  );

  // For channel and channel theme support.
  const forceChannel = Platform.select({
    web: fallbackChannel?.force ? fallbackChannel?.key : undefined,
    default: appConfig.mobileChannel || 'SkillHero',
  });
  const { data: channel, isLoading: channelIsLoading } = useApiRequest(
    api.channels.fetchMe(forceChannel),
    {
      fallbackData: fallbackChannel,
      keepPreviousData: true,
    }
  );
  const theme = merge(CHANNEL_THEME[channel?.key ?? 'SkillHero'] ?? {}, channel?.themeData);

  useEffect(() => {
    // Update `identity_channel` in analytics collected by Firebase
    setUserProperty('identity_channel', channel?.key ?? 'No channel');

    // Update `identity_channel` in analytics collected with GTM.
    pushToDataLayer('identity_channel', channel?.key ?? 'No channel');
  }, [channel, setUserProperty]);

  useEffect(() => {
    if (me && window && (window as any)['chmln']) {
      (window as any)['chmln'].identify(me.id, {
        uid_hash: me.uidHash,
        email: me.email,
        name: me.firstName || me.lastName ? `${me.firstName} ${me.lastName}`.trim() : me.username,
      });
    }
  }, [me]);

  const isLoading =
    meIsLoading ||
    profileIsLoading ||
    orgProfileIsLoading ||
    identitiesIsLoading ||
    channelIsLoading;

  useAsync(async () => {
    if (loadState === LOAD_STATE.INIT) {
      if (initializing) {
        return;
      }

      if (!token) {
        setLoadState(LOAD_STATE.LOADED);
        return;
      }

      if (!isLoading) {
        return;
      }

      setLoadState(LOAD_STATE.LOADING);
    }

    if (loadState === LOAD_STATE.LOADING) {
      if (isLoading) {
        return;
      }

      if (!token) {
        setLoadState(LOAD_STATE.LOADED);
        return;
      }

      if (meError) {
        await logout();
      }

      if (!me) {
        return;
      }

      if (!identity) {
        return;
      }

      setLoadState(LOAD_STATE.LOADED);
    }

    if (loadState === LOAD_STATE.LOADED) {
      if ((!me || !identity) && isLoading) {
        setLoadState(LOAD_STATE.LOADING);
        return;
      }
    }
  }, [loadState, initializing, token, isLoading, me, logout]);

  return (
    <ProfileContext.Provider
      value={{
        me,
        profile,
        orgProfile: orgProfile as APIOrganizationProfile, // we are sure that you can access sure your full profile
        identity,
        identities: identities ?? [],
        isLoading: loadState < LOAD_STATE.LOADED,
        theme,
        channel,
        mutateMe,
        mutateProfile,
        mutateOrgProfile: mutateOrgProfile as KeyedMutator<APIOrganizationProfile>, // we are sure that you can access sure your full profile
        mutateIdentities,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
