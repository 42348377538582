var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9g-9N16IkYP8tiorw55lM"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN =
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  process.env.NEXT_PUBLIC_DEV__SENTRY_DSN ||
  process.env.NEXT_PUBLIC_STAGING__SENTRY_DSN ||
  process.env.NEXT_PUBLIC_PROD__SENTRY_DSN;
const SENTRY_ENVIRONMENT =
  process.env.NEXT_PUBLIC_ENVIRONMENT ||
  process.env.NEXT_PUBLIC_DEV__ENVIRONMENT ||
  process.env.NEXT_PUBLIC_STAGING__ENVIRONMENT ||
  process.env.NEXT_PUBLIC_PROD__ENVIRONMENT;

Sentry.init({
  dsn: SENTRY_DSN,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: SENTRY_ENVIRONMENT,

  ignoreErrors: [
    // Unresolved browser error, ignore as advised on https://github.com/getsentry/sentry/issues/61469
    /Object\.prototype\.hasOwnProperty\.call\([a-zA-Z]+,"telephone"\)/g,
  ],
});
