import * as base from '../base';
import { APIList, APISearchParams, Options } from '../types';
import { APIIdentity } from './identities';
import {
  APIOrganizationUserInvite,
  APIProcessOrganizationInviteResponse,
} from './organizationProfiles';
import { APIMinimalUser } from './users';

export type APIInviteCheckInput = {
  organization_id: number;
  email: string;
};

export type APIInviteCheck = {
  inviteExists: boolean;
};

export type APIInvitePending = {
  id: number;
  email: string;
  role: APIIdentity['role'];
  user: APIMinimalUser;
};

export class OrganizationInviteAPI extends base.ReadOnlyAPIBase<APIOrganizationUserInvite> {
  baseUrl = 'organizations/invite/';

  detail(id: number) {
    return this._get<APIOrganizationUserInvite>(`${this.baseUrl}${id}/`);
  }

  check(data: APIInviteCheckInput) {
    return this._post<APIInviteCheck>(`${this.baseUrl}check/`, { json: data });
  }

  pendingInvites(page?: number, limit?: number, searchParams?: APISearchParams, options?: Options) {
    return this._list<APIList<APIInvitePending>>(
      `${this.baseUrl}pending-invites/`,
      page,
      limit,
      searchParams,
      options
    );
  }

  resendAllOrgInvites(data: APIProcessOrganizationInviteResponse) {
    return super.postEmpty(`${this.baseUrl}resend-all/`, {
      json: data,
    });
  }
}
