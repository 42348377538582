import { app } from 'app/lib/firebase';
import { getAnalytics, setUserProperties } from 'firebase/analytics';
import { useCallback, useMemo } from 'react';

const analytics = typeof window === 'undefined' ? undefined : getAnalytics(app);

export default function useSetUserProperty() {
  const setUserProperty = useCallback((key: string, value: string) => {
    if (!analytics) {
      return;
    }

    setUserProperties(analytics, { [key]: value });
  }, []);

  return useMemo(() => ({ setUserProperty }), [setUserProperty]);
}
