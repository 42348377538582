import appConfig from 'app/config/appConfig';

const pushToDataLayer = (key: string, value: string) => {
  if (appConfig.gtmGa4DestinationId) {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      [key]: value ?? 'No channel',
    });
  }
};

export default pushToDataLayer;
